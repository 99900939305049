@import '~styles/variables';
@import '~styles/bootstrap/mixins';

$wrapper-padding-bottom: $grid-padding;

//
.wrapper {
  padding-bottom: $wrapper-padding-bottom;
}

.title {
  font-weight: 500;
  color: $gray-500;
}