// Variables
// Variables should follow the `$component-state-property-size` formula for
// consistent naming. Ex: $nav-link-disabled-color and $modal-content-box-shadow-xs.

// Bootstrap's variables
// Options
//
// Quickly modify global styling by enabling or disabling optional features.
$enable-caret:                                true;
$enable-rounded:                              true;
$enable-shadows:                              false;
$enable-gradients:                            false;
$enable-transitions:                          true;
$enable-prefers-reduced-motion-media-query:   true;
$enable-hover-media-query:                    false; // Deprecated, no longer affects any compiled CSS
$enable-grid-classes:                         true;
$enable-pointer-cursor-for-buttons:           true;
$enable-print-styles:                         true;
$enable-responsive-font-sizes:                false;
$enable-validation-icons:                     true;
$enable-deprecation-messages:                 true;

// Color system

$white:           #ffffff;  
$gray-100:        #f8f9fa;
$gray-150:        #f3f3f3;
$gray-200:        #e9ecef;
$gray-300:        #dee2e6;
$gray-400:        #ced4da;
$gray-500:        #adb5bd;
$gray-600:        #6c757d;
$gray-700:        #495057;
$gray-800:        #343a40;
$gray-900:        #212529;
$black:           #000000;

$blue:            #2C7BE5;
$indigo:          #727cf5;
$purple:          #6b5eae;
$pink:            #ff679b;
$red:             #E63757;
$orange:          #fd7e14;
$yellow:          #F6C343;
$green:           #33af6d;
$teal:            #02a8b5;
$cyan:            #39afd1;

$primary:         $blue;
$secondary:       $gray-500;
$success:         $green;
$info:            $cyan;
$warning:         $yellow;
$danger:          $red;
$light:           $gray-100;
$dark:            $gray-800;


$grays: (
  "100":          $gray-100,
  "200":          $gray-200,
  "300":          $gray-300,
  "400":          $gray-400,
  "500":          $gray-500,
  "600":          $gray-600,
  "700":          $gray-700,
  "800":          $gray-800,
  "900":          $gray-900,
);

$colors: (
  "blue":         $blue,
  "purple":       $purple,
  "red":          $red,
  "yellow":       $yellow,
  "green":        $green,
  "white":        $white,
  "gray":         $gray-600,
  "gray-dark":    $gray-800,
  "black":        $gray-900,
);

$theme-colors: (
  "primary":      $primary,
  "secondary":    $secondary,
  "success":      $success,
  "info":         $info,
  "warning":      $warning,
  "danger":       $danger,
  "light":        $light,
  "dark":         $dark,
);

// Grid breakpoints
//
// Define the minimum dimensions at which your layout will change,
// adapting to different screen sizes, for use in media queries.

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px
);

// Grid columns
//
// Set the number of columns and specify the width of the gutters.

$grid-columns:                12;
$grid-gutter-width:           30px;
$grid-row-columns:            6;
$grid-padding:                $grid-gutter-width / 2;

// Typography
//
// Font, line-height, and color for body text, headings, and more.

// stylelint-disable value-keyword-case
$font-family-poppins:         'Poppins', sans-serif;
$font-family-noto-sans-kr:    'Noto Sans KR', sans-serif;
$font-family-base:            $font-family-poppins;

$font-size-base:              1rem; // Assumes the browser default, typically `16px`
$font-size-lg:                $font-size-base * 1.25;
$font-size-sm:                $font-size-base * .875;

$font-weight-lighter:         lighter;
$font-weight-light:           300;
$font-weight-normal:          400;
$font-weight-bold:            600;
$font-weight-bolder:          bolder;

$font-weight-base:            $font-weight-normal;
$line-height-base:            1.5;

$h1-font-size:                $font-size-base * 2.5;
$h2-font-size:                $font-size-base * 2;
$h3-font-size:                $font-size-base * 1.75;
$h4-font-size:                $font-size-base * 1.5;
$h5-font-size:                $font-size-base * 1.25;
$h6-font-size:                $font-size-base;

// $headings-margin-bottom:      $spacer / 2 !default;
// $headings-font-family:        null !default;
$headings-font-weight:        600;
// $headings-line-height:        1.2 !default;
// $headings-color:              null !default;


// Components
//
// Define common padding and border radius sizes and more.
$line-height-lg:              1.5;
$line-height-sm:              1.5;

$border-width:                1px;
$border-color:                $gray-300;

$border-radius:               .25rem;
$border-radius-lg:            .3rem;
$border-radius-sm:            .2rem;

$rounded-pill:                50rem;


$transition-base:             all .2s ease-in-out !default;
$transition-fade:             opacity .15s linear !default;
$transition-collapse:         height .35s ease !default;

// Project's variables
// 
$padding-horizontal:          $grid-padding;

// navigation
$navigation-height:           64px;
$navigation-height-sm:        120px;

// brand logo
$brand-logo-margin-top:       50px;

// facebook chat plugin
$fb-chat-icon-size:           80px;