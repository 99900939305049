@import '~styles/variables';
@import '~styles/bootstrap/mixins';

$wrapper-horizontal-padding:  0;
$wrapper-vertical-padding:    $grid-gutter-width;

$use-case-horizontal-padding: 34px;
$use-case-margin-bottom:      20px;
$use-case-wrapper-horizontal-margin:  $use-case-horizontal-padding * -1;
// 
.wrapper {
  padding: $wrapper-vertical-padding $wrapper-horizontal-padding;
  padding-bottom: 100px;
}

.useCase {
  margin-bottom:  $use-case-margin-bottom;

  @include media-breakpoint-up(sm) {
    padding-left:   $use-case-horizontal-padding;
    padding-right:  $use-case-horizontal-padding;
  }

  &Wrapper {
    @include media-breakpoint-up(sm) {
      margin-left:    $use-case-wrapper-horizontal-margin;
      margin-right:   $use-case-wrapper-horizontal-margin;
    }
  }

  // .useCaseIcon
  &Icon {
    font-size: 2rem;
  }

  // .useCaseTitle
  &Title {
    @include media-breakpoint-up(md) {
      min-height: 84px;
    }
  }

  // .useCaseDescription
  &Description {
    margin-top: 0.5rem;
    text-align: center;
  }
}