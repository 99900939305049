@import '~styles/variables';
@import '~styles/bootstrap/mixins';

$wrapper-padding-top:    $grid-padding;
$wrapper-padding-bottom: $grid-padding;

//
.wrapper {
  text-align:     center;
  padding-top:    $wrapper-padding-top;
  padding-bottom: $wrapper-padding-bottom;
}

.title {
  margin-bottom: 1rem;
}