@import '~styles/variables';
@import '~styles/bootstrap/mixins';

//
.dialog {
  :global {
    .modal-content {
      border: none;
    }

    .modal-title {
      font-weight: 500;
      &::first-letter {
        text-transform: uppercase;
      }
    }
  }
}

.closeButton {
  font-size: 1rem;
}