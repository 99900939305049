@import '~styles/variables';
@import '~styles/bootstrap/mixins';

$wrapper-padding-top:    30px;
$wrapper-padding-bottom: 15px;

//
.wrapper {
  padding-top:    $wrapper-padding-top;
  padding-bottom: $wrapper-padding-bottom;
}