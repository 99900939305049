/*
 * @NOTE: Prepend a `~` to css file paths that are in your node_modules
 *        See https://github.com/webpack-contrib/sass-loader#imports
 */

@import '~@fortawesome/fontawesome-free/css/all.css';
@import '~react-datepicker/dist/react-datepicker.css';
@import './styles';

html {
  &[lang="ko"] {
    &, body {
      font-family: $font-family-noto-sans-kr;
    }
  }
}

.capitalize-first-letter {
  &::first-letter {
    text-transform: capitalize;
  }
}