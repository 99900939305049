@import '~styles/variables';
@import '~styles/bootstrap/mixins';

$containerBg:                 $white;
$container-padding-top:       $navigation-height + $brand-logo-margin-top + $grid-padding;
$container-padding-top-md:    $navigation-height + $grid-padding;
$container-padding-top-sm:    $navigation-height-sm + $grid-padding;

$content-horizontal-padding:  $padding-horizontal;

// the :export directive is the magic sauce for webpack
:export {
  brandLogoMarginTop: $brand-logo-margin-top;
}

.wrapper {
  display: block;
  position: relative;
  width: 100%;
  height: 100%;
  min-height: 100vh;
}

.container {
  display: flex;
  flex-direction: column;
  min-height: 100%;
  background: $containerBg;
  padding-top: $container-padding-top;

  @include media-breakpoint-down(md) {
    padding-top: $container-padding-top-md;
  }

  @include media-breakpoint-down(sm) {
    padding-top: $container-padding-top-sm;
  }
}

.content {
  display: block;
  flex-grow: 1;

  // .contentHorizontalPadding
  &HorizontalPadding {
    padding-left: $content-horizontal-padding;
    padding-right: $content-horizontal-padding;
  }
}