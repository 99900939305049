@import '~styles/variables';
@import '~styles/bootstrap/mixins';

$font-family: inherit;

$dialog-width:  300px;

$picker-min-height: 326px;

$outside-month-color:   $gray-500;
$selected-day-bg-color: $primary;
$today-border-color:    $primary;

// 
.dialog {
  max-width: 100%;
  
  @media screen and (min-width: $dialog-width + 20px) {
    width: $dialog-width;
    margin-left: auto;
    margin-right: auto;
  }
}

.picker {
  width:          100%;
  min-height:     $picker-min-height;
  border:         none;
  border-radius:  0;
  font-family:    inherit;
  font-size:      inherit;

  :global {
    * {
      // -webkit-touch-callout: none; /* iOS Safari */
      // -webkit-user-select: none; /* Safari */
      // -khtml-user-select: none; /* Konqueror HTML */
      //   -moz-user-select: none; /* Old versions of Firefox */
      //     -ms-user-select: none; /* Internet Explorer/Edge */
              user-select: none; /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */
    }

    .react-datepicker {
      &__month-container {
        width: 100%;
      }

      &__header {
        border-radius: 0;
      }

      &__day-name,
      &__day,
      &__time-name {
        outline:      none;
        line-height:  2.2rem;
        width:        2.2rem;
      }

      &__day {
        &--outside-month {
          color: $outside-month-color;
        }

        &--today {
          background-color: rgba($today-border-color, 0.2);
          border-radius: 0.3rem;
        }

        &--selected {
          background-color: $selected-day-bg-color;
        }
      }
    }
    
  }
}