@import '~styles/variables';
@import '~styles/bootstrap/mixins';

$wrapper-padding-top:     30px;
$wrapper-padding-bottom:  30px;

$feature-img-width:       400px;

//
.wrapper {
  padding-top:    $wrapper-padding-top;
  padding-bottom: $wrapper-padding-bottom;
}

.featureImg {
  display: block;
  object-fit: contain;
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;

  @include media-breakpoint-up(md) {
    width: $feature-img-width;
  }
}