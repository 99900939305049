@import '~styles/variables';
@import '~styles/bootstrap/mixins';

$wrapper-margin-bottom:   50px;

//
.wrapper {
  display:        block;
  width:          100%;
  position:       relative;
  margin-bottom:  $wrapper-margin-bottom;
}

.group {
  margin-bottom: 1.5rem;

  // .groupTitle
  &Title {
    text-transform: uppercase;
  }
}

.listStrings {
  padding-left: 19px;
}

.history {
  margin-bottom: 1rem;

  // .historyWrapper
  &Wrapper {
    margin-bottom: 1rem;
  }

  // .historyTitle
  &Title {
    font-weight: 500;
    margin-bottom: 0;
  }

  // .historyDescription
  &Description {
    color: $gray-700;
    font-size: 0.875rem;
    margin-bottom: 0;
  }

  // .historyPeriod
  &Period {
    color: $gray-700;
    font-size: 0.875rem;
    margin-bottom: 0;
  }
}
