.wrapper {
  position: relative;
  height: 100%;
  width: auto;
  display: block;
}

.image {
  object-fit: contain;
  max-height: 100%;
  max-width: 100%;
  height: 100%;
  width: auto;
}