@import '~styles/variables';
@import '~styles/bootstrap/mixins';

$wrapper-horizontal-padding:  0;
$wrapper-vertical-padding:    $grid-gutter-width;

.wrapper {
  padding: $wrapper-vertical-padding $wrapper-horizontal-padding;
  padding-bottom: 100px;
}

.articleWrapper {
  margin-bottom: $grid-padding * 2;
}