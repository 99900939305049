@import '~styles/variables';
@import '~styles/bootstrap/mixins';

$wrapper-padding-top:    30px;
$wrapper-padding-bottom: 30px;

//
.wrapper {
  padding-top:    $wrapper-padding-top;
  padding-bottom: $wrapper-padding-bottom;
  text-align:     center;
}

.symbol {
  font-size: 6rem;
  font-weight: bold;
}

.title {
  margin-bottom: 1rem;
}

.description {
  margin-bottom: 1rem;
}