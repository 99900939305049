@import '~styles/variables';
@import '~styles/bootstrap/mixins';

$wrapper-padding-top:     30px;
$wrapper-padding-bottom:  15px;

$section-margin-bottom:   1.5rem;

//
.wrapper {
  padding-top:    $wrapper-padding-top;
  padding-bottom: $wrapper-padding-bottom;
}

.section {
  margin-bottom: $section-margin-bottom;

  // .sectionTitle
  &Title {
    font-weight: 500;
    margin-bottom: 0.75rem;
  }

  // .sectionContainer
  &Container {
    display:  block;
    width:    100%;
  }
}