@import '~styles/variables';
@import '~styles/bootstrap/mixins';

$wrapper-vertical-padding:    10px;
$wrapper-horizontal-padding:  10px;
$wrapper-background-color:    $gray-100;
$wrapper-shadow:              0px 1px 2px 0px rgba(0,0,0,0.3);
$wrapper-radius:              8px;

.wrapper {
  position: relative;
  max-width: 100%;
  width: 100%;
  overflow: hidden;
  box-shadow: $wrapper-shadow;
  border-radius: $wrapper-radius;
  background:$wrapper-background-color;
  padding: $wrapper-vertical-padding $wrapper-horizontal-padding;
  cursor: pointer;
}

.image {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;

  &Wrapper {
    display: block;
    height: 0;
    width: auto;
    padding-top: 56.25%;
    position: relative;
    overflow: hidden;
    margin: $wrapper-vertical-padding * -1;
    margin-bottom: $wrapper-vertical-padding;
  }
}

.date {
  font-size: 0.8rem;
  font-weight: bold;
  color: $gray-700;
}

.title {
  display: -webkit-box;
  text-overflow: ellipsis;
  overflow: hidden;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  height: 1.5rem * 2;
  margin: 5px auto;
  font-size: 0.9rem;
}