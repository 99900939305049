@import '~styles/variables';
@import '~styles/bootstrap/mixins';

$card-border-radius:     0.6rem;
//
.wrapper {
  width: 440px;
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;
}

.card {
  overflow: hidden;

  &:first-child {
    border-top-left-radius: $card-border-radius;
    border-bottom-left-radius: $card-border-radius;
  }

  &:last-child {
    border-top-right-radius: $card-border-radius;
    border-bottom-right-radius: $card-border-radius;
  }

  :global {
    .card-header {
      background-color: inherit;
      border-bottom: none;
      text-align: center;
      font-size: 1.2rem;
      padding-bottom: 0;
    }

    .card-body {
      text-align: center;
    }

    .card-footer {
      background-color: inherit;
      border-top: none;
      text-align: center;
    }
  }
}

.zeroOpacity {
  opacity: 0;
}