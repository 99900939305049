@import '~styles/variables';
@import '~styles/bootstrap/mixins';

$wrapper-horizontal-padding:  0;
$wrapper-vertical-padding:    0;

$section-left-color:          #f7f6f6;
$section-right-color:          $white;

$container-child-horizontal-padding:  $grid-padding;
$container-child-vertical-padding:    $grid-gutter-width * 6;
// 
.wrapper {
  position: relative;
  padding: $wrapper-vertical-padding $wrapper-horizontal-padding;
}

.backdrop {
  width: 100%;
  display: flex;
  position: absolute;
  top: $wrapper-vertical-padding;
  bottom: $wrapper-vertical-padding;
  justify-items: stretch;
  align-items: stretch;
  flex-flow: row;

  @include media-breakpoint-down(sm) {
    display: none;
  }

  // .backdropLeft
  &Left {
    background-color: $section-left-color;
  }

  // .backdropRight
  &Right {
    background-color: $section-right-color;
  }
}

.container {
  &Left, &Right {
    padding: $container-child-vertical-padding $container-child-horizontal-padding;

    @include media-breakpoint-down(sm) {
      padding: ($container-child-vertical-padding / 6) $container-child-horizontal-padding;
    }
  }

  // .containerLeft
  &Left {
    background-color: $section-left-color;

    @include media-breakpoint-up(md) {
      padding-right: $container-child-vertical-padding / 3;
    }
  }

  // .containerRight
  &Right {
    background-color: $section-right-color;
  }
}

.content {
  text-align: center;

  @include media-breakpoint-up(md) {
    text-align: left;
    // width: 330px;
    max-width: 100%;
  }
}

.button {
  // buttonFindYourExpert
  &FindYourExpert {
    &::first-letter {
      text-transform: capitalize;
    }
  }
}