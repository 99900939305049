@import '~styles/variables';
@import '~styles/bootstrap/mixins';

$wrapper-horizontal-padding:  0;
$wrapper-vertical-padding:    $grid-gutter-width;

$backdrop-background-image:   url('~common/assets/bg-home-hero.jpg');

$content-width:               440px;

$feature-img-width:           500px;
// 
.wrapper {
  position: relative;
  padding: $wrapper-vertical-padding $wrapper-horizontal-padding;
}

.backdrop {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: $backdrop-background-image;
}

.content {
  max-width: 100%;

  @include media-breakpoint-up(md) {
    width: $content-width;
  }
}

.featureImg {
  display: block;
  object-fit: contain;
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;

  @include media-breakpoint-up(md) {
    width: $feature-img-width;
  }
}