@import '~styles/variables';
@import '~styles/bootstrap/mixins';

$picture-width:           60px;
$picture-width-sm:        100px;

$wrapper-margin-bottom:   50px;
$wrapper-grid-gap:        $grid-padding;
$wrapper-grid-areas:      'side header' 'main main';
$wrapper-grid-columns:    $picture-width auto;
$wrapper-grid-areas-sm:   'side header' 'main main';
$wrapper-grid-columns-sm: $picture-width-sm auto;

$top-info-padding-right:  140px;
//
.wrapper {
  display:        grid;
  width:          100%;
  position:       relative;
  grid-gap:       $wrapper-grid-gap;
  margin-bottom:  $wrapper-margin-bottom;

  @include media-breakpoint-up(md) {
    grid-template-areas:    $wrapper-grid-areas;
    grid-template-columns:  $wrapper-grid-columns;
  }
  @include media-breakpoint-down(md) {
    grid-template-areas:    $wrapper-grid-areas-sm;
    grid-template-columns:  $wrapper-grid-columns-sm;
  }
}

.side {
  grid-area: side;
}

.header {
  grid-area:     header;

  @include media-breakpoint-up(lg) {
    position:       relative;
    padding-right:  $top-info-padding-right;
  }
}

.main {
  grid-area: main;
}

.picture {
  width:                $picture-width;
  max-width:            100%;
  background-color:     transparent;
  background-repeat:    no-repeat;
  background-position:  center center;
  background-size:      cover;
  z-index:              20;
  position:             relative;

  @include media-breakpoint-down(md) {
    width: $picture-width-sm;
  }

  // .pictureWrapper
  &Wrapper {
    position:         relative;
    background-color: $gray-400;
  }

  // .pictureFirstChar
  &FirstChar {
    position: absolute;
    z-index:  10;
    top:      0;
    left:     0;
    width:    100%;
    height:   100%;
    display:          flex;
    flex-direction:   row;
    justify-content:  center;
    align-items:      center;
    font-size:        2.3rem;
    color:            $white;
  }
}

.buttonViewDetail {
  display: inline-block;
  &::first-letter {
    text-transform: uppercase;
  }
}

.divider {
  margin-top: 0;

  @include media-breakpoint-up(lg) {
    margin-right:  $top-info-padding-right;
  }
}

.buttonContact {
  @include media-breakpoint-up(lg) {
    position:       absolute;
    top:            0;
    right:          0;
  }

  @include media-breakpoint-down(sm) {
    width: 100%;
  }
}

.name {
  margin-bottom: 5px;
}

.location {
  margin-bottom: 0;

  @include media-breakpoint-down(md) {
    margin-bottom: 10px;
  }
}

.introduction {
  margin-bottom: 15px;
  color: $gray-700;

  @include media-breakpoint-up(lg) {
    padding-right:  $top-info-padding-right;
  }

  &__experiences::first-letter {
    text-transform: uppercase;
  }
}

.expertise {
  margin-right: 5px;
  color: $gray-600;

  &::before {
    content: '#';
    display: inline;
    // margin-right: 5px;
    font-family: inherit;
    font-size: inherit;
  }

  &:last-of-type {
    margin-right: 0;
  }
}