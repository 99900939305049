@import '~styles/variables';
@import '~styles/bootstrap/mixins';

$collapse-top:            $navigation-height;
$collapse-top-sm:         $navigation-height-sm;
$collapse-background:     $white;

$navLink-color:               #033075;
$navLink-font-weight:         500;
$navLink-padding-horizontal:  1rem;

// 
.wrapper {
  background-color: transparent;
}

.collapse {
  @include media-breakpoint-down(md) {
    background-color: $collapse-background;
    position:         absolute;
    top:              $collapse-top;
    left:             0;
    right:            0;
  }

  @include media-breakpoint-down(sm) {
    top: $collapse-top-sm;
    margin-top: -0.5rem;
    margin-right: -1rem;
    margin-left: -1rem;
    left: 0;
    right: 0;
  }
}

.nav {
  background-color: $white;

  @include media-breakpoint-up(lg) {
    background-color: transparent;
    margin-left:  auto;
    margin-right: 1rem;
  }

  // .navLink
  &Link {
    color:          $navLink-color !important;
    text-transform: uppercase;
    font-weight:    $navLink-font-weight;
    padding-right:  $navLink-padding-horizontal !important;
    padding-left:   $navLink-padding-horizontal !important;

    @include media-breakpoint-up(lg) {
      margin-left:          5px;
      border-bottom-width:  2px;
      border-bottom-color:  transparent;
      border-bottom-style:  solid;
    }

    @include media-breakpoint-down(md) {
      text-align: right;
    }

    &:hover, &:global(.active) {
      @include media-breakpoint-up(lg) {
        border-bottom-color: $navLink-color;
      }
    }
  }
}