@import '~styles/variables';
@import '~styles/bootstrap/mixins';

.wrapper {
  background-color: transparent;
}

.nav {

  // .navLink
  &Link {
    color: $black !important;
    padding-left: 0.5rem !important;
    padding-right: 0.5rem !important;

    &:global(.active) {
      text-decoration: underline;
    }
  }
}