@import '~styles/variables';
@import '~styles/bootstrap/mixins';

$wrapper-padding-top:     30px;
$wrapper-padding-bottom:  15px;

//
.wrapper {
  padding-top:    $wrapper-padding-top;
  padding-bottom: $wrapper-padding-bottom;
}

.title {
  font-weight:  500;
  color:        $gray-700;
}

.description {
  color:        $gray-700;
}

.verifying {
  font-size:    1.5rem;
  color:        $gray-700;
}