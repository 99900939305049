@import '~styles/variables';
@import '~styles/bootstrap/mixins';

$wrapper-margin-bottom: 1rem;

//
.wrapper {
  margin-bottom: $wrapper-margin-bottom;
}

.title {
  font-weight: 500;
  margin-bottom: 0.75rem;
}

.container {
  display:  block;
  width:    100%;
}