@import '~styles/variables';
@import '~styles/bootstrap/mixins';

$wrapper-padding-top:     30px;
$wrapper-padding-bottom:  30px;

$title-font-size-lg:      $h3-font-size;
$title-font-size-sm:      $h4-font-size;
//
.wrapper {
  padding-top:    $wrapper-padding-top;
  padding-bottom: $wrapper-padding-bottom;

  @include media-breakpoint-down(md) {
    padding-left:   0;
    padding-right:  0;
  }
}

.title {
  font-size: $title-font-size-lg;

  @include media-breakpoint-down(sm) {
    font-size: $title-font-size-sm;
  }
}

.moreInfo {
  font-size: 0.9rem;
  color: $gray-600;

  @include media-breakpoint-down(sm) {
    font-size: 0.8rem;
  }
}

.author {
  font-weight: 500;
}

.content {
  text-align: left;

  figure {
    text-align: center;
  }

  img {
    max-width: 100%;
  }

  a {
    color: inherit;
    text-decoration: underline;
  }

  p:last-child {
    display: none;
  }
}