textarea:focus, 
textarea.form-control:focus, 
input.form-control:focus, 
input[type=text]:focus, 
input[type=password]:focus, 
input[type=email]:focus, 
input[type=number]:focus, 
select:focus,
[type=text].form-control:focus, 
[type=password].form-control:focus, 
[type=email].form-control:focus, 
[type=tel].form-control:focus, 
[contenteditable].form-control:focus,
.form-control:focus,
.btn:focus, .btn.focus,
button:focus {
  box-shadow: none;
  outline: none;
}

.form-check {
  position: relative;

  .form-check-label {
    &::before {
      font-family: 'Font Awesome\ 5 Free';
      vertical-align: baseline;
      display: inline-block;
      content: "\f0c8";
      font-size: 1rem;
      margin-right: 5px;
    }
  }

  .form-check-input {
    position: absolute !important;
    cursor: pointer;
    margin-left: 0;
    opacity: 0;

    &:checked + .form-check-label {
      &::before {
        content: "\f14a";
        font-weight: bold;
        color: $primary;
      }
    }
  }
}
