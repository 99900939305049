/* noto-sans-kr-100 - korean */
@font-face {
  font-family: 'Noto Sans KR';
  font-style: normal;
  font-weight: 100;
  src: url('~common/assets/fonts/noto-sans-kr/noto-sans-kr-v12-korean-100.eot'); /* IE9 Compat Modes */
  src: local('Noto Sans KR Thin'), local('NotoSansKR-Thin'),
    url('~common/assets/fonts/noto-sans-kr/noto-sans-kr-v12-korean-100.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('~common/assets/fonts/noto-sans-kr/noto-sans-kr-v12-korean-100.woff2') format('woff2'), /* Super Modern Browsers */
    url('~common/assets/fonts/noto-sans-kr/noto-sans-kr-v12-korean-100.woff') format('woff'), /* Modern Browsers */
    // url('~common/assets/fonts/noto-sans-kr/noto-sans-kr-v12-korean-100.ttf') format('truetype'), /* Safari, Android, iOS */
    url('~common/assets/fonts/noto-sans-kr/noto-sans-kr-v12-korean-100.svg#NotoSansKR') format('svg'); /* Legacy iOS */
}

/* noto-sans-kr-300 - korean */
@font-face {
  font-family: 'Noto Sans KR';
  font-style: normal;
  font-weight: 300;
  src: url('~common/assets/fonts/noto-sans-kr/noto-sans-kr-v12-korean-300.eot'); /* IE9 Compat Modes */
  src: local('Noto Sans KR Light'), local('NotoSansKR-Light'),
    url('~common/assets/fonts/noto-sans-kr/noto-sans-kr-v12-korean-300.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('~common/assets/fonts/noto-sans-kr/noto-sans-kr-v12-korean-300.woff2') format('woff2'), /* Super Modern Browsers */
    url('~common/assets/fonts/noto-sans-kr/noto-sans-kr-v12-korean-300.woff') format('woff'), /* Modern Browsers */
    // url('~common/assets/fonts/noto-sans-kr/noto-sans-kr-v12-korean-300.ttf') format('truetype'), /* Safari, Android, iOS */
    url('~common/assets/fonts/noto-sans-kr/noto-sans-kr-v12-korean-300.svg#NotoSansKR') format('svg'); /* Legacy iOS */
}

/* noto-sans-kr-regular - korean */
@font-face {
  font-family: 'Noto Sans KR';
  font-style: normal;
  font-weight: 400;
  src: url('~common/assets/fonts/noto-sans-kr/noto-sans-kr-v12-korean-regular.eot'); /* IE9 Compat Modes */
  src: local('Noto Sans KR Regular'), local('NotoSansKR-Regular'),
    url('~common/assets/fonts/noto-sans-kr/noto-sans-kr-v12-korean-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('~common/assets/fonts/noto-sans-kr/noto-sans-kr-v12-korean-regular.woff2') format('woff2'), /* Super Modern Browsers */
    url('~common/assets/fonts/noto-sans-kr/noto-sans-kr-v12-korean-regular.woff') format('woff'), /* Modern Browsers */
    // url('~common/assets/fonts/noto-sans-kr/noto-sans-kr-v12-korean-regular.ttf') format('truetype'), /* Safari, Android, iOS */
    url('~common/assets/fonts/noto-sans-kr/noto-sans-kr-v12-korean-regular.svg#NotoSansKR') format('svg'); /* Legacy iOS */
}

/* noto-sans-kr-500 - korean */
@font-face {
  font-family: 'Noto Sans KR';
  font-style: normal;
  font-weight: 500;
  src: url('~common/assets/fonts/noto-sans-kr/noto-sans-kr-v12-korean-500.eot'); /* IE9 Compat Modes */
  src: local('Noto Sans KR Medium'), local('NotoSansKR-Medium'),
    url('~common/assets/fonts/noto-sans-kr/noto-sans-kr-v12-korean-500.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('~common/assets/fonts/noto-sans-kr/noto-sans-kr-v12-korean-500.woff2') format('woff2'), /* Super Modern Browsers */
    url('~common/assets/fonts/noto-sans-kr/noto-sans-kr-v12-korean-500.woff') format('woff'), /* Modern Browsers */
    // url('~common/assets/fonts/noto-sans-kr/noto-sans-kr-v12-korean-500.ttf') format('truetype'), /* Safari, Android, iOS */
    url('~common/assets/fonts/noto-sans-kr/noto-sans-kr-v12-korean-500.svg#NotoSansKR') format('svg'); /* Legacy iOS */
}

/* noto-sans-kr-700 - korean */
@font-face {
  font-family: 'Noto Sans KR';
  font-style: normal;
  font-weight: 700;
  src: url('~common/assets/fonts/noto-sans-kr/noto-sans-kr-v12-korean-700.eot'); /* IE9 Compat Modes */
  src: local('Noto Sans KR Bold'), local('NotoSansKR-Bold'),
    url('~common/assets/fonts/noto-sans-kr/noto-sans-kr-v12-korean-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('~common/assets/fonts/noto-sans-kr/noto-sans-kr-v12-korean-700.woff2') format('woff2'), /* Super Modern Browsers */
    url('~common/assets/fonts/noto-sans-kr/noto-sans-kr-v12-korean-700.woff') format('woff'), /* Modern Browsers */
    // url('~common/assets/fonts/noto-sans-kr/noto-sans-kr-v12-korean-700.ttf') format('truetype'), /* Safari, Android, iOS */
    url('~common/assets/fonts/noto-sans-kr/noto-sans-kr-v12-korean-700.svg#NotoSansKR') format('svg'); /* Legacy iOS */
}

/* noto-sans-kr-900 - korean */
@font-face {
  font-family: 'Noto Sans KR';
  font-style: normal;
  font-weight: 900;
  src: url('~common/assets/fonts/noto-sans-kr/noto-sans-kr-v12-korean-900.eot'); /* IE9 Compat Modes */
  src: local('Noto Sans KR Black'), local('NotoSansKR-Black'),
    url('~common/assets/fonts/noto-sans-kr/noto-sans-kr-v12-korean-900.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('~common/assets/fonts/noto-sans-kr/noto-sans-kr-v12-korean-900.woff2') format('woff2'), /* Super Modern Browsers */
    url('~common/assets/fonts/noto-sans-kr/noto-sans-kr-v12-korean-900.woff') format('woff'), /* Modern Browsers */
    // url('~common/assets/fonts/noto-sans-kr/noto-sans-kr-v12-korean-900.ttf') format('truetype'), /* Safari, Android, iOS */
    url('~common/assets/fonts/noto-sans-kr/noto-sans-kr-v12-korean-900.svg#NotoSansKR') format('svg'); /* Legacy iOS */
}